@font-face {
  font-family: "Poppins Light";
  src: url("./fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Poppins Light Italic";
  src: url("./fonts/poppins/Poppins-LightItalic.ttf") format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Poppins Italic";
  src: url("./fonts/poppins/Poppins-Italic.ttf") format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "Poppins SemiBold";
  src: url("./fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Poppins SemiBold Italic";
  src: url("./fonts/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Poppins Bold";
  src: url("./fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Poppins Bold Italic";
  src: url("./fonts/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-style: italic;
}

.gradient-bg {
  background-image: linear-gradient(
    to right bottom,
    #55b8a5,
    #00b3bc,
    #00aad8,
    #009df0,
    #008af8,
    #1f7efa,
    #3b71f9,
    #5562f6,
    #6b5ef7,
    #7e58f7,
    #9052f6,
    #a14af4
  );
}

.gradient-text {
  background: linear-gradient(
          90deg,
          #F7A420 11.92%,
          #EA670F 47.2%,
          #EA670F 74.09%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.waves {
  position: relative;
  width: 100%;
  /*height: 15vh;*/
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* /* Shrinking for mobile */
@media (max-width: 768px) {
  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}